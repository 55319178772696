import React from "react";
import AppTable from "../../../../components/layout/AppTable";
import { getFriendlyTimeStamp } from "../../../../services/utils";

const AuditTrailTable = ({ list }) => {
  console.log(JSON.stringify(list));
  return (
    <div>
      <AppTable
        title={""}
        calcpx="300px"
        data={list}
        columns={[
            { title: "Operation", field: "operation", width: "12%" },
            { title: "Type", field: "outputType", width: "12%" },
            { title: "Entity name", field: "tableName", width: "12%" },
            { title: "Username", field: "user", width: "13%" },
            { title: "Role", field: "name", width: "15%" },
            { title: "Batch number", field: "batchNumber", width: "18%" },
            {
                title: "Operation date",
                render: (rd) => getFriendlyTimeStamp(rd.published_at),
                width: "18%",
            },
        ]}
      />
    </div>
  );
};

export default AuditTrailTable;