import {
  Button,
  Card,
  CardActions,
  CardContent, Icon, makeStyles, Tooltip, Typography
} from "@material-ui/core";
import { CloudDownload, CloudUploadOutlined, DoneAll, HourglassEmpty, Publish, VisibilityOutlined, Warning } from "@material-ui/icons";
import React from "react";
import FlexSpaceBetween from "../../components/layout/FlexSpaceBetween";

import bdcLogo from "../../assets/bdc.png";
import mcbLogo from "../../assets/mcb.png";
import FlexCenter from "../../components/layout/FlexCenter";
import { getFriendlyTimeStamp } from "../../services/utils";
import styleVariables from "../../services/variables";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: styleVariables.primaryColor,
    color: "white",
    "&:hover": {
      backgroundColor: styleVariables.secondaryColor,
      color: "white",
    },
    paddingLeft: 40,
    paddingRight: 40,
  },
  disabled: {
    
  },
  button: {
    margin: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      minWidth: 84,
      paddingLeft: 8,
      paddingRight: 8,
      "& .MuiButton-startIcon": {
        margin: 0
      }
    }
  },
  buttonText: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 12,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: 19,
    }
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex'
   }
}));

const AnvaCard = ({ data, user, kies, size = "small", setUploadStatus }) => {
  const {
    id,
    batchNumber,
    outputType,
    total,
    created_at,
    anvaRecords,
    status,
    errorMessage,
  } = data;

  const userViewer = "VIEWER";

  const statusError = "ERROR";
  const statusConverted = "CONVERTED";
  const statusUploaded = "UPLOADED";
  const statusPending = "PENDING";

  const naamVariant = size === "small" ? "h6" : "h4";
  const txtVariant = size === "small" ? "body1" : "h6";
  const totalRecords = anvaRecords && anvaRecords.length;
  
  const isUploadedBtnDisabled = status === statusUploaded || status === statusError || user.role.name === userViewer;
  const isDownloadBtnDisabled = status === statusError || user.role.name === userViewer;
  const isShowRecordsBtnDisabled = status === statusError;

  const showStatus = () => {
    if (status === statusError) {
      return status + ": " + errorMessage;
    }
    return status;
  };

  const defineTextColorAndIcon = () => {
    let color;
    let statusIcon;
    let fontSize = "1.1rem";

    switch (
      status
    ) {
      case statusError:
        color = "#f50057";
        statusIcon = <Warning />;
        fontSize = "0.9rem";
        break;
      case statusConverted:
        color = "#008900";
        statusIcon = <DoneAll />;
        break;
      case statusUploaded:
        color = "#0D4DA5";
        statusIcon = <Publish />;
        break;
      case statusPending:
        color = "#fe9528";
        statusIcon = <HourglassEmpty />;
        break;
      default:
        color = "#000000";
        break;
    }
    return {
      style: { color: color, fontSize: fontSize },
      statusIcon
    };
  };

  const classes = useStyles();

  return (
    <Card style={{ display: "grid", gridTemplateColumns: "1fr 3fr", padding: "1.1em", borderRadius: 12, boxShadow: "0 4px 12px -6px rgba(0,0,0,0.35)", }}>
      <FlexCenter style={{ padding: "1em" }}>
        {outputType === "MCB_FEP" ? (
          <img src={mcbLogo} alt="Logo of MCB" />
        ) : (
          <img src={bdcLogo} alt="Logo of BDC" />
        )}
      </FlexCenter>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardContent>
          <FlexSpaceBetween>
            <Typography variant={naamVariant}>
            {outputType} - {batchNumber}
            </Typography>
          </FlexSpaceBetween>
          <>
            <Typography variant={txtVariant} style={{ marginTop: "0.35em" }}>
              {getFriendlyTimeStamp(created_at)}
            </Typography>
            <Typography variant={txtVariant} style={{ marginTop: "0.75em", ...defineTextColorAndIcon().style}} className={classes.wrapIcon}>
              <Icon fontSize="4rem" style={{ marginRight: "0.3em"}}>{defineTextColorAndIcon().statusIcon}</Icon>
              {showStatus()}
            </Typography>
            <Typography variant={txtVariant} style={{ marginTop: "0.65em" }}>
              Records: {totalRecords}
            </Typography>
            <Typography variant={naamVariant} style={{ marginTop: "0.3em" }}>Total: {total}</Typography>
          </>
        </CardContent>
        <CardActions>
          <FlexSpaceBetween>
            <Tooltip title={"Show " + totalRecords + " records"}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => kies("show", data)}
              disabled={isShowRecordsBtnDisabled}
              startIcon={<VisibilityOutlined />}
              className={classes.button}
            >
              <span className={classes.buttonText}>Show {totalRecords}</span>
            </Button>
            </Tooltip>
            <Tooltip title="Uploaded to bank">
            <Button
              id={id}
              variant="outlined"
              color="secondary"
              onClick={() => {
                kies("upload", data);
                setUploadStatus({"fileId": data.id});
              }}
              disabled={isUploadedBtnDisabled}
              startIcon={<CloudUploadOutlined />}
              className={classes.button}
            >
              <span className={classes.buttonText}>Uploaded</span>
            </Button>
            </Tooltip>
            <Tooltip title="Click to download to PC"><Button
              variant="contained"
              color="primary"
              onClick={() => kies("download", data)}
              disabled={isDownloadBtnDisabled}
              startIcon={<CloudDownload />}
              className={classes.button}
            >
              <span className={classes.buttonText}>DownLoad</span>
            </Button>
            </Tooltip>
          </FlexSpaceBetween>
        </CardActions>
      </div>
    </Card>
  );
};

export default AnvaCard;
