import React from "react";
import BarChart from "./BarChart";

const VaccinatiePerDag = ({ data = [] }) => {
  return (
    <BarChart
      data={data}
      indexBy="datumRegistratie"
      ylegend="Count"
      legend="Clients Processed"
    />
  );
};

export default VaccinatiePerDag;
