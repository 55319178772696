import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Helmet } from "react-helmet";
import MenuAppBar from "./MenuAppBar";

import { Typography } from "@material-ui/core";
import ibisLogo from "../../assets/logo-ibis-white.png";
import logo from "../../assets/logo-pink.jpg";
import { useAuth } from "../../services/AuthProvider";
import styleVariables from "../../services/variables";
import FixedPosition from "./FixedPosition";
import FlexCenter from "./FlexCenter";
import MainNavigation from "./MainNavigation";

const drawerWidth = 200;

const Layout = ({ children, title, padding = true, centered = false }) => {
  const classes = useStyles({ padding });
  const { role } = useAuth();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Helmet>
        <title>Citizens ANVA | {role} </title>
      </Helmet>
      <MenuAppBar className={classes.appBar} title={title} />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
        height="100vh"
      >
        <div className={classes.toolbar}>
          <div className={classes.imgcon}>
            <img
              className={classes.img}
              src={logo}
              alt="Logo"
              width={drawerWidth - 30}
              style={{marginBottom: "2em"}}
            />
          </div>
        </div>
        {/* <Divider /> */}
        <MainNavigation drawerWidth={drawerWidth} />
        <FixedPosition drawerWidth={drawerWidth}>
          <div style={{ margin: "1em" }}>
            <a style={{ textAlign: "center", alignSelf: "center", textDecoration: "none", display: "block", margin: "0 auto",   }} href="https://www.ibis-management.com/"><img src={ibisLogo} alt="Logo of IBIS" style={{ width: "10em" }} /></a>
            <Typography align="center" style={{ marginTop: "1em", fontSize: "0.9rem" }}>{process.env.REACT_APP_VERSION || "local development"}</Typography>
          </div>
        </FixedPosition>
      </Drawer>
      {centered && (
        <main className={classes.centered}>
          <FlexCenter column>{children}</FlexCenter>
        </main>
      )}
      {!centered && <main className={classes.content}>{children}</main>}
    </div>
  );
};

export default Layout;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: "white",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    overflowY: "hidden",
  },
  // "#064c78",
  drawerPaper: {
    backgroundColor: styleVariables.primaryColor, 
    color: "white",
    width: drawerWidth,
    overflowY: "hidden",
  },
  imgcon: {
    // padding: "1em ",
    // backgroundColor: "#fff",
  },
  img: {
    width: "100%",
    backgroundColor: "#fff",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: (props) => (props.padding ? theme.spacing(3) : 0),
    marginTop: 64,
    // height: "80vh",
  },
  centered: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: (props) => (props.padding ? theme.spacing(3) : 0),
    marginTop: 64,
    height: "calc(100vh-200px)",
  },
}));
