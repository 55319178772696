import React from "react";
import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Dashboard, ArrowUpward, History } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../services/AuthProvider";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: "0.5em",
    minWidth: '3em', 
    color: "#fff",
  },
}));

const MainNavigation = () => {
  const { role } = useAuth();
  return <DashboardMenu role={role}/>;
};

export default MainNavigation;

const DashboardMenu = ({role}) => {
  const navigateTo = useNavigate();

  const classes = useStyles();

  const handleListItemClick = (path) => {
    navigateTo(path);
  };

  return (
    <List>
      <ListItem button onClick={() => handleListItemClick("/dashboard")}>
        <ListItemIcon className={classes.icon}>
          <Dashboard />
        </ListItemIcon> 
        <ListItemText primary={"Dashboard"} />
      </ListItem>
      <ListItem button onClick={() => handleListItemClick("/anva-files")}>
        <ListItemIcon className={classes.icon}>
          <ArrowUpward />
        </ListItemIcon> 
        <ListItemText primary={"Export Files"} />
      </ListItem>
      {role === "admin" && (
         <>
          <ListItem button onClick={() => handleListItemClick("/audit-trail")}>
            <ListItemIcon className={classes.icon}>
              <History />
            </ListItemIcon> 
             <ListItemText primary={"Audit Trail"} />
          </ListItem>
        </>
       )}
    </List>
  );
};