import React, { useContext, useState } from "react";
// import createModal from "@abdulghani/promise-modal";
// import {  fetchData } from "./api";
// import StopDialog from "../pages/Inteker/InteekForm/forms/StopDialog.jsx";
// import { daysFromNow } from "./utils";
// import moment from "moment";

export const InwonerContext = React.createContext();
export const useInwoner = () => useContext(InwonerContext);

const InwonerProvider = ({ children }) => {
  const [step, setStep] = useState(1);
  // const showDialog = createModal(StopDialog);
  // const [apiCallInprogress, setApiCallInprogress] = useState(false);
  // const eindeStep = 100;

  return (
    <InwonerContext.Provider
      value={{
        setStep,
        step,
      }}
    >
      {children}
    </InwonerContext.Provider>
  );
};

export default InwonerProvider;
