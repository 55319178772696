import React, { Suspense } from "react";
import { useQuery } from "react-query";

import UsersTable from "./UsersTable";
import { fetchData } from "../../../../services/api";
import { useAuth } from "../../../../services/AuthProvider";

const UsersData = ({ role }) => {
  const { token } = useAuth();

  const { data } = useQuery(["/users", token], fetchData, {
    suspense: true,
  });

  return (
    <p>
      <UsersTable list={data.filter((user) => user.role.name === role)} />
    </p>
  );
};

const UsersList = ({ role }) => {
  return (
    <>
      <Suspense fallback={`Loading ${role} Users... `}>
        <UsersData role={role} />
      </Suspense>
    </>
  );
};
export default UsersList;
