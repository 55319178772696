// import { find } from "lodash";
import React, { useContext } from "react";
// import React, { useState, useContext } from "react";
// import { useQuery } from "react-query";
// import { fetchData } from "./api";
const AppContext = React.createContext();

// const refetchOnWindowFocus = false;
export const useAppConfig = () => useContext(AppContext);

const AppProvider = ({ children }) => {
  return <AppContext.Provider value={{}}>{children}</AppContext.Provider>;
};

export default AppProvider;
