import React from "react";
import AppTable from "../../components/layout/AppTable";

const AnvaRecords = ({ data }) => {
  return (
    <AppTable
      data={data}
      title={"Anva Records"}
      calcpx="600px"
      minWidth={"600px"}
      columns={[
        { title: "Type", field: "type" },
        { title: "Client", field: "clientName" },
        { title: "Bank account", field: "clientAccountNumber" },
        { title: "Amount", field: "amount", type: "numeric" },
      ]}
    />
  );
};

export default AnvaRecords;
