import React, { useState } from "react";
import { useQuery } from "react-query";
import AppModal from "../../components/layout/AppModal";

import { fetchData, updateStatus, addAuditTrail } from "../../services/api";
import { useAuth } from "../../services/AuthProvider";
import AnvaCardsList from "./AnvaCardsList";
import AnvaRecords from "./AnvaRecords";

const AnvaFiles = () => {
  const { user, token } = useAuth();
  const [open, setOpen] = useState(false);
  const [records, setRecords] = useState([]);
  const [, setUploadStatus] = useState({"fileId": 0});
  const [anvaFiles, setAnvaFiles] = useState([]);

  const downloadData = async (data) => {
    await addAuditTrail({
      "operation": "DOWNLOADED",
      "tableName": "anva-file",
      "tableRecordId": data.id,
      "user": user.username
    }, token);

    const fileType = "text/csv;charset=utf-8;";

    try {
      const url = window.URL.createObjectURL(
        new Blob([data.exportText], { type: fileType })
      );

      const link = document.createElement("a");
      link.href = url;
      
      let fileName = data.fileName; 
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click(); 
    } catch (error) {}
  };
  const kies = async (type, file) => {
    switch (type) {
      case "show":
        setOpen(true);
        setRecords(file.anvaRecords);
        break;
      case "download":
        await downloadData(file);
        break;
      case "upload":
        await updateStatus(file.id, {
          "status": "UPLOADED", 
          "user": user
        }, token);
        updateStatusInList(file.id, "UPLOADED");
        break;
      default:
        break;
    }
  };
  const updateStatusInList = (id, newStatus) => {
    let updatedList = anvaFiles.map(item => {
      if (item.id === id) {
        return {...item, status: newStatus}; 
      }
      return item;  
    });
    setAnvaFiles(updatedList);
  };
  const { status } = useQuery([`/anva-files?_sort=created_at:DESC`, token], fetchData, {
    suspense: false,
    onSuccess: setAnvaFiles
  });
  
  return (
    <>
      <AppModal
        open={open}
        cancel={() => setOpen(false)}
        cancelButtonName="close"
        minWidth="800px">
        <AnvaRecords data={records} />
      </AppModal>
      <AnvaCardsList user={user} loadStatus={status} list={anvaFiles} kies={kies} setUploadStatus={setUploadStatus} /> 
    </>
  );
};

export default AnvaFiles;
