import React from "react";
import AuditTrailList from "./components/AuditTrailList";
import Layout from "../../../components/layout/Layout";

const AuditTrailPage = () => {
  return (
    <Layout title="Audit Trail">
      <AuditTrailList />
    </Layout>
  );
};
export default AuditTrailPage;
