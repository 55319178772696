import React from "react";

import { Divider, Icon, Typography } from "@material-ui/core";
import { Today } from "@material-ui/icons";
import { groupBy } from "lodash";
import GridAutoFill from "../../components/layout/GridAutoFill";
import { cardTitle, getFriendlyDate } from "../../services/utils";
import styleVariables from "../../services/variables";
import AnvaCard from "./AnvaCard";


const AnvaCardsList = ({ user, loadStatus = 'success', list = [], kies = () => {}, setUploadStatus }) => {
  if (loadStatus === 'loading') {
    return <Typography>Loading...</Typography>;
  }
  if (loadStatus === 'error') {
    return <Typography>An error occurred. Please try again.</Typography>;
  }
  if (list.length === 0) {
    return <Typography>No Records Available</Typography>;
  }

  const data = list.map((item) => ({
    ...item,
    creationDate: cardTitle(item.created_at),
  }));
  const perDate = groupBy(data, "creationDate");

  return (
    <>
      {Object.keys(perDate).map((date, idx) => (
        <div key={idx}>
          <Divider style={{ marginTop: "3em" }} />
          <div style={{display: "flex", justifyContent: "center" }}>
            <Typography style={{ display: "flex", alignItems: "center", fontSize: "1.7rem" }}>
              <Icon fontSize="4rem" style={{ marginRight: "0.75em", color: styleVariables.darkColor }}><Today /></Icon>
              {getFriendlyDate(date)}
            </Typography>
          </div>
          <Divider style={{ marginBottom: "1.5em" }} />
          <GridAutoFill px={600} gap="1.5em">
            {perDate[date].map((file) => (
              <AnvaCard key={file.id} user={user} data={file} kies={kies} setUploadStatus={setUploadStatus} />
            ))}
          </GridAutoFill>
        </div>
      ))}
    </>
  );
};

export default AnvaCardsList;
