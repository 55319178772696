import React, { Suspense } from "react";
import { hot } from "react-hot-loader";
import { ReactQueryDevtools } from "react-query-devtools";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";
import AppProvider from "./services/AppProvider";
import AuthProvider from "./services/AuthProvider";
import FlowsProvider from "./services/FlowsProvider";
import PrivateRoute from "./services/PrivateRoute";

import Loading from "./components/Loading";
import Landing from "./pages/Landing";
import NotFound from "./pages/NotFound";
import Dashboard from "./pages/Dashboard/Page";

import Users from "./pages/Admin/Users/Page";
import AuditTrail from "./pages/Admin/AuditTrail/Page";

import ANVA from "./pages/ANVA/Page";
import AnvaFiles from "./pages/ANVA/AnvaFiles";
import AnvaRecords from "./pages/ANVA/AnvaRecords";

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <AuthProvider>
        <AppProvider>
          <FlowsProvider>
            <BrowserRouter>
              <Routes basename="/">
                <Route path="/" element={<Landing />} />
                <Route path="/landing" element={<Landing />} />
                <PrivateRoute path="/admin" element={ANVA}>
                  <PrivateRoute path="/" element={Dashboard} />
                </PrivateRoute>
                <PrivateRoute path="/payment_officer" element={ANVA}>
                  <PrivateRoute path="/" element={Dashboard} />
                </PrivateRoute>
                <PrivateRoute path="/viewer" element={ANVA}>
                  <PrivateRoute path="/" element={Dashboard} />
                </PrivateRoute>
                <PrivateRoute path="/dashboard" element={ANVA}>
                  <PrivateRoute path="/" element={Dashboard} />
                </PrivateRoute>
                <PrivateRoute path="/anva-files" element={ANVA}>
                  <PrivateRoute path="/" element={AnvaFiles} />
                  <PrivateRoute path="/:id" element={AnvaRecords} />
                </PrivateRoute>
                <PrivateRoute path="/audit-trail" element={AuditTrail} />
                <PrivateRoute path="/users" element={Users} />
                <PrivateRoute path="*" element={NotFound} />
              </Routes>
            </BrowserRouter>
          </FlowsProvider>
        </AppProvider>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </Suspense>
  );
};

export default hot(module)(App);
