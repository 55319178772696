const { createMuiTheme } = require("@material-ui/core");

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#E5549D"
    },
    secondary: {
      main: "#C64887"
    },
    containedPrimary: {
      color: "#ffffff"
    }
  },
});

export default theme;
