import { Card, CardContent } from "@material-ui/core";
import React from "react";
import GridAutoFill from "../../../components/layout/GridAutoFill";
import GeslachtChart from "./GeslachtChart";
import VaccinatiePerDag from "./VaccinatiePerDag";

const Chart = ({ data = {}, height = "300px" }) => {
  return (
    <GridAutoFill px={600} style={{ marginTop: "1rem" }}>
      <ChartCard height={height}>
        <GeslachtChart data={data.geslacht} />
      </ChartCard>
      <ChartCard height={height}>
        <VaccinatiePerDag data={data.chart} />
      </ChartCard>
    </GridAutoFill>
  );
};

export default Chart;

const ChartCard = ({ children, height }) => (
  <Card>
    <CardContent style={{ height }}>{children}</CardContent>
  </Card>
);
