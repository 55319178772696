import React, { useState, Suspense } from "react";
import { useQuery } from "react-query";

import AuditTrailTable from "./AuditTrailTable";
import { fetchData } from "../../../../services/api";
import { useAuth } from "../../../../services/AuthProvider";

const AuditTrailData = () => {
  const { token } = useAuth();

  const [auditTrails, setAuditTrails] = useState([]);

  useQuery(["/audit-trails/all?_sort=published_at:DESC", token], fetchData, {
    suspense: false,
    onSuccess: setAuditTrails
  });

  return (
    <AuditTrailTable list={auditTrails} />
  );
};

const AuditTrailList = () => {
  return (
    <>
      <Suspense fallback={`Loading audit trail... `}>
        <AuditTrailData />
      </Suspense>
    </>
  );
};
export default AuditTrailList;