import MaterialTable from "material-table";
import React from "react";

const AppTable = ({
  data,
  columns,
  options,
  calcpx = "200px",
  title = "App Table",
  maxWidth = "100%",
  minWidth = "100%",
  ...props
}) => {
  return (
    <div style={{ maxWidth, minWidth }}>
      <MaterialTable
        columns={columns}
        data={data}
        title={title}
        options={{
          pageSize: 25,
          pageSizeOptions: [25, 50],
          headerStyle: { position: "sticky", top: 0 },
          maxBodyHeight: `calc(100vh - ${calcpx})`,
          ...options,
        }}
        {...props}
      />
    </div>
  );
};

export default AppTable;
