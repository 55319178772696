import React, { Suspense } from "react";
import { useQuery } from "react-query";
import AppStats from "../../../components/layout/AppStats";
import GridAutoFill from "../../../components/layout/GridAutoFill";

import { fetchData } from "../../../services/api";
import { useAuth } from "../../../services/AuthProvider";
import Chart from "./Chart";

const TotalsdData = () => {
  const { token } = useAuth();

  const { data } = useQuery(["/anva-file/dashboard/totals", token], fetchData, {
    suspense: true,
    refetchInterval: 60000,
  });

  return (
    <>
      <GridAutoFill px={200}>
        {data.stats && data.stats.map((stat) => <AppStats stat={stat} />)}
      </GridAutoFill>
      <Chart data={data} height="400px" />
    </>
  );
};

const Totals = () => {
  return (
    <>
      <Suspense fallback={"Loading Totals... "}>
        <TotalsdData />
      </Suspense>
    </>
  );
};
export default Totals;
