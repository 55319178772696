import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const AppStats = ({ stat }) => {
  const { query, type } = stat;
  return (
    <Card style={{ backgroundColor: stat.color }}>
      <CardActionArea
        disabled={query === undefined}
        component={Link}
        to={`/bakuna/admin/lijst/${query}/${type}`}
        state={{ showLoactie: true }}
      >
        <CardContent style={{ textAlign: "center" }}>
          <Typography variant="h3">{stat.count}</Typography>
          <Typography variant="button">{stat.label}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default AppStats;
