import React from "react";
import moment from "moment-timezone";
import AppTable from "../../../../components/layout/AppTable";

const dateFormat = "DD-MM-YYYY";

const UsersTable = ({ list }) => {
  return (
    <div>
      <AppTable
        title={""}
        calcpx="300px"
        data={list}
        columns={[
          { title: "Username", field: "username" },
          { title: "Email", field: "email" },
          { title: "Role", field: "role.name" },

          {
            title: "Created at",
            render: (rd) => moment(rd.created_at).format(dateFormat),
          },
        ]}
      />
    </div>
  );
};

export default UsersTable;
