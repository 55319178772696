import { AppBar, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import UsersList from "./components/UsersList";

const UsersPage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <>
      <AppBar position="static">
        <Tabs value={tabIndex} onChange={(e, idx) => setTabIndex(() => idx)}>
          <Tab label="Intekers" />
          <Tab label="Prikkers" />
          <Tab label="Huisarts" />
          {/* <Tab label="Border Control" /> */}
        </Tabs>
      </AppBar>

      <div style={{ marginTop: "1em" }}>
        {tabIndex === 0 && <UsersList role="INTEKER" />}
        {tabIndex === 1 && <UsersList role="PRIKKER" />}
        {tabIndex === 2 && <UsersList role="HUISARTS" />}
        {/* {tabIndex === 2 && <UsersList role="CTB-SUPPORT" />} */}
      </div>
    </>
  );
};
export default UsersPage;
