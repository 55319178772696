import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useForm } from "react-hook-form";
import SaveButton from "../layout/SaveButton";
import Spacer from "../layout/Spacer";
import AppField from "./AppField";

const useStyles = makeStyles({
  root: {
    maxWidth:850,
    minWidth: 450,
    padding: "1.5em",
    borderRadius: "0.8em",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)"
  },
  button: {
    backgroundColor: "#1F78B4",
    color: "white",
    "&:hover": {
      backgroundColor: "#7eb823",
      color: "white",
    },
  },
  error: {
    color: "#d32027",
    fontWeight: "500",
    fontSize: "1.1rem"
  },
});

const LoginForm = ({ submit, error }) => {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();

  return (
    <Card className={classes.root}>
      <form onSubmit={handleSubmit(submit)}>
        <CardContent>
          <Typography color="secondary" style={{fontSize: "1.2rem"}}>
            Please enter  your credentials
          </Typography>
          <Spacer size={26} />
          <input type="hidden" name="id" ref={register} />
          <AppField
            reg={register({ required: true })}
            type="email"
            label="Email"
            name="identifier"
          />
          <Spacer size={15} />
          <AppField
            reg={register({ required: true })}
            type="password"
            label="Password"
            name="password"
          />
          <Spacer size={12} />
          {error && (
            <Typography className={classes.error} variant="subtitle1">
              {error}
            </Typography>
          )}
          <Spacer size={26} />
          <SaveButton label="SIGN IN" style={{ fontSize: "1rem" }} />
        </CardContent>
      </form>
    </Card>
  );
};

export default LoginForm;
