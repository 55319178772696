import moment from "moment-timezone";

const apiFormat = "YYYY-MM-DD";
const cardTitleFormat = "ddd DD-MMM-YY";
const timestamp = "DD-MM-YYYY HH:mm:ss A";
const friendlyDate = "ddd MMMM D, YYYY";
const friendlyTimestamp = "MMMM Do YYYY, h:mm:ss A";

export const curaDate = (date) => moment(date).format("DD MMM YYYY");
export const curaDateTime = (date) => moment(date).format("DD MMM");
// export const curaDateTime = (date) => moment(date).format("DD MMM");
export const getAge = (dob) => moment().diff(dob, "years");

export const today = () => moment().format(apiFormat);
export const yesterday = () => moment().subtract(1, "days").format(apiFormat);
export const isToday = (date) => date === moment().format(apiFormat);
export const dayForward = (date) =>
  moment(date).add(1, "days").format(apiFormat);
export const dayBack = (date) =>
  moment(date).subtract(1, "days").format(apiFormat);

export const cardTitle = (date) => moment(date).format(cardTitleFormat);
export const getTimeStamp = (date) => moment(date).format(timestamp);
export const getFriendlyDate = (date) => moment(date).format(friendlyDate);
export const getFriendlyTimeStamp = (date) => moment(date).format(friendlyTimestamp);

export const fromNow = (date) => moment(date).fromNow();
export const daysFromNow = (date) => moment().diff(date, "days");
