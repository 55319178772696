import { Typography } from "@material-ui/core";
import React from "react";
import { Navigate } from "react-router-dom";
import logo from "../assets/logo.png"; //"../assets/logo-massy-united-insurance.jpg";
import LoginForm from "../components/forms/LoginForm";
import FlexCenter from "../components/layout/FlexCenter";
import { useAuth } from "../services/AuthProvider";

const Landing = () => {
  const { login, role, error } = useAuth();

  const formLogin = (values) => {
    login(values);
  };

  if (role) {
    return <Navigate to={`anva-files`} />;
  }
  return (
    <FlexCenter height={"100vh"} column justify="start">
      <div style={{ marginTop: "3em" }}>
        <img src={logo} alt="Logo Citizens United" width="325" />
      </div>
      <div style={{ padding: "3em 0", color: "#064c78", textAlign: "center" }}>
        <Typography variant="h4" component="h4">ANVA file converter
        </Typography>
        <Typography variant="h4" component="span" style={{ color: "#f58220", marginTop: "2em" }}>
          {process.env.REACT_APP_VERSION}
        </Typography>
      </div>
      <LoginForm submit={formLogin} error={error} />
    </FlexCenter>
  );
};

export default Landing;
