import React from "react";
import Totals from "./components/Totals";

const DashBoardPage = () => {
  return (
    <>
      <Totals />
    </>
  );
};
export default DashBoardPage;
